<!--
 * @Author: your name
 * @Date: 2021-03-16 18:42:57
 * @LastEditTime: 2021-05-22 11:55:49
 * @LastEditors: gufs
 * @Description: In User Settings Edit
 * @FilePath: /h5prezi(vue-cli)/src/views/HelpCenter.vue
-->
<template>
  <div class="partners">
    <div class="title">
      我们的客户
    </div>
    <div class="showcase">
      <div
        v-for="partner in partnerInfo"
        :key="partner.name"
        class="partner-info"
      >
        <img :src="'/dist/media/static-page/h5prezi/img/partner/' + partner.img + '.png'">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  component: {},
  data() {
    return {
      partnerInfo: [
        {
          name: "人民日报",
          img: "rmrb",
        },
        {
          name: "棒约翰",
          img: "byh",
        },
        {
          name: "三只松鼠",
          img: "szss",
        },
        {
          name: "台峡糕点",
          img: "txgd",
        },
        {
          name: "煌上煌",
          img: "hsh",
        },
        {
          name: "华莱士",
          img: "hls",
        },
        {
          name: "飞利浦",
          img: "flp",
        },
        {
          name: "杨国福",
          img: "ygf",
        },
        {
          name: "正新",
          img: "zx",
        },
        {
          name: "冠顺",
          img: "gs",
        },
        {
          name: "米食先生",
          img: "msxs",
        },
        {
          name: "包子客",
          img: "bzk",
        },
        {
          name: "鹿港小镇",
          img: "lgxz",
        },
        {
          name: "汉堡王",
          img: "hbw",
        },
        {
          name: "智微智能",
          img: "zwzn",
        },
      ],
    };
  },
};
</script>

<style scoped>
.partners {
  text-align: center;
  margin-bottom: 150px;
}
.title {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  margin: 100px 0;
}
.showcase {
  width: 1200px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
}
.partner-info {
  padding: 24px;
}
</style>