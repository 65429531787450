<!--
 * @Author: your name
 * @Date: 2021-03-16 18:42:57
 * @LastEditTime: 2021-05-22 11:15:20
 * @LastEditors: gufs
 * @Description: In User Settings Edit
 * @FilePath: /h5prezi(vue-cli)/src/views/help-center.vue
-->
<template>
  <div class="help-center">
    <div
      class="banner"
      :style="bannerStyle"
    >
      <div class="banner-title">
        帮助中心
      </div>
    </div>
    <div class="navigation-warp">
      <div class="navigation">
        <div
          class="navigation-item"
          :class="page === 'manual' ? 'activate' : ''"
          @click="changePage('manual')"
        >
          <span class="navigation-title">
            <i class="ri-book-line" />
            <span>操作手册</span>
          </span>
        </div>
        <div
          class="navigation-item"
          :class="page === 'problem' ? 'activate' : ''"
          @click="changePage('problem')"
        >
          <span class="navigation-title">
            <i class="ri-question-line" />
            <span>常见问题</span>
          </span>
        </div>
        <div
          class="navigation-item"
          :class="page === 'tool' ? 'activate' : ''"
          @click="changePage('tool')"
        >
          <span class="navigation-title">
            <i class="ri-global-line" />
            <span>资源导航</span>
          </span>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        v-if="page === 'manual'"
        class="manual article"
      >
        <div class="menu-warp"> 
          <div
            v-for="menu in manualMenu"
            :key="menu.title"
            class="menu-item"
          >
            {{ menu.title }}
            <div
              v-for="(subMenu, index) in menu.subMenu"
              :key="index"
              class="submenu-item"
              :class="subMenu.article===manualArticle ? 'active' : ''"
              :article="subMenu.article"
              @click="changeManualArticle"
            >
              {{ subMenu.title }}
            </div>
          </div>
        </div>
        <div
          class="article-warp"
          v-html="manualArticles[manualArticle]"
        />
      </div>
      <div
        v-else-if="page === 'problem'"
        class="problem article"
      >
        <div class="menu-warp">
          <div
            v-for="menu in problemsMenu"
            :key="menu.title"
            class="menu-item"
          >
            {{ menu.title }}
            <div
              v-for="(subMenu, index) in menu.subMenu"
              :key="index"
              class="submenu-item"
              :class="subMenu.article===problemArticle ? 'active' : ''"
              :article="subMenu.article"
              @click="changeProblemArticle"
            >
              {{ subMenu.title }}
            </div>
          </div>
        </div>
        <div
          class="article-warp"
          v-html="problemsArticles[problemArticle]"
        />
      </div>
      <div
        v-else-if="page === 'tool'"
        class="tool-navigation"
      >
        <div
          class="tool-navigation-warp"
          name="design-tool"
        >
          <div class="tool-type-title">
            设计工具
          </div>
          <div class="tool-item-warp">
            <a
              v-for="designTool in designTools"
              :key="designTool.title"
              class="tool-item"
              :href="designTool.href"
              target="_blank"
            >
              <div class="tool-itme-title">{{ designTool.title }}</div>
              <div class="tool-itme-desc">{{ designTool.desc }}</div>
            </a>
          </div>
        </div>
        <div
          class="tool-navigation-warp"
          name="clip-tool"
        >
          <div class="tool-type-title">
            素材资源
          </div>
          <div class="tool-item-warp">
            <a
              v-for="clipTool in clipTools"
              :key="clipTool.title"
              class="tool-item"
              :href="clipTool.href"
              target="_blank"
            >
              <div class="tool-itme-title">{{ clipTool.title }}</div>
              <div class="tool-itme-desc">{{ clipTool.desc }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { problemsMenu, problemsArticles } from "../store/help/problems.js";
import { manualMenu, manualArticles } from "../store/help/manual.js";
import {designTools, clipTools} from "../store/help/tool.js"
export default {
  data() {
    return {
      bannerStyle: "background-image: url(/dist/media/static-page/h5prezi/img/helpCenter/banner.png)",
      designTools,
      clipTools,
      page: 'manual',
      problemsMenu,
      manualMenu,
      problemsArticles,
      manualArticles,
      manualArticle: 'environment',
      problemArticle: 'autoReboot'
    };
  },
  methods: {
    changePage(page) {
      this.page = page;
    },
    changeManualArticle(e) {
      this.manualArticle = e.srcElement.getAttribute('article');
    },
    changeProblemArticle(e) {
      this.problemArticle = e.srcElement.getAttribute('article');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.help-center {
  font-family: PingFang SC;
  .banner {
    height: 256px;
    width: 100%;
    text-align: center;
    .banner-title {
      font-size: 48px;
      font-weight: 400;
      color: #ffffff;
      line-height: 256px;
    }
  }
  .navigation-warp {
    width: 100%;
    height: 114px;
    background: #f6f7f9;
    text-align: center;
    .navigation {
      display: flex;
      position: relative;
      top: 35px;
      width: 468px;
      margin: 0 auto;
      .navigation-item {
        font-size: 18px;
        width: 128px;
        height: 44px;
        background: #edeff3;
        border-radius: 5px;
        color: #666666;
        margin-left: 14px;
        margin-right: 14px;
        vertical-align: middle;
        cursor: pointer;
        .navigation-title {
          align-items: center;
          display: inline-flex;
          line-height: 44px;
          span {
            margin-left: 8px;
          }
        }
      }
      .navigation-item.activate {
        background: #E2EDFA;
        .navigation-title {
          color: #0773FC;
        }
      }
    }
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    .article {
      .menu-warp {
        width: 280px;
        min-height: 1057px;
        background: #F6F7F9;
        font-size: 14px;
        font-weight: 400;
        padding-top: 40px;
        padding-bottom: 50px;
        display: inline-block;
        .menu-item {
          color: #0773FC;
          line-height: 40px;
          margin-bottom: 20px;
          padding-left: 20px;
          padding-right: 10px;
          .submenu-item {
            color: #666666;
            padding-left: 20px;
            padding-right: 10px;
            cursor: pointer;
          }
          .submenu-item.active {
            color: #222222;
          }
        }
      }
      .article-warp {
        height: 100%;
        width: calc(100% - 280px);
        display: inline-block;
        padding: 50px;
        vertical-align: top;
        & a,
        & a:link,
        & a:visited,
        & a:hover,
        & a:active {
          color: #096DD9;
        }
        article {
          width: 100%;
          p {
            video,
            image {
              width: 100%;
            }
          }
        }
      }
    }
    .tool-navigation {
      margin-top: 64px;
      margin-bottom: 150px;
      .tool-navigation-warp {
        margin-top: 40px;
        .tool-type-title {
          font-size: 16px;
          font-weight: 400;
          color: #222222;
          display: inline-block;
          position: relative;
          top: 12px;
        }
        .tool-type-title:before {
          content: "";
          position: relative;
          left: -8px;
          height: 16px;
          width: 2px;
          top: 2px;
          display: inline-block;
          background-color: #0773fc;
        }
        .tool-item-warp {
          display: inline-flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 1115px;
          .tool-item {
            width: 236px;
            height: 74px;
            background: #f6f7f9;
            border-radius: 5px;
            margin-left: 42px;
            margin-bottom: 20px;
            .tool-itme-title {
              font-size: 14px;
              font-weight: 400;
              color: #222222;
              top: 14px;
              left: 20px;
              position: relative;
            }
            .tool-itme-desc {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              top: 20px;
              left: 20px;
              position: relative;
            }
          }
        }
      }
    }

  }
}
</style>
