/*
 * @Author: gufs
 * @Date: 2021-05-13 12:14:37
 * @LastEditors: gufs
 * @LastEditTime: 2021-05-13 12:15:51
 * @Description: file content
 */
export const designTools = [
    {
        title: "格式工厂",
        desc: "万能的多媒体格式转换软件",
        href: "http://www.pcgeshi.com/",
    },
    {
        title: "草料二维码",
        desc: "专业的二维码生成工具",
        href: "https://cli.im/",
    },
    {
        title: "Tinypng",
        desc: "专业的在线图片压缩工具",
        href: "https://tinypng.com/",
    },
    {
        title: "在线JPG/PNG压缩",
        desc: "Optimizilla在线图像压缩工具",
        href: "https://color.adobe.com/zh/create/color-wheel",
    },
    {
        title: "Color Scheme",
        desc: "专业在线配色工具",
        href: "https://www.shejidaren.com/examples/tools/color-scheme/",
    },
    {
        title: "ilovepdf",
        desc: "完全免费的在线PDF转换工具",
        href: "https://www.ilovepdf.com/",
    },
    {
        title: "eZGIF",
        desc: "Gif图像编辑与压缩",
        href: "https://ezgif.com/",
    },
    {
        title: "在线抠图",
        desc: "免费抠图网站",
        href: "https://www.remove.bg/",
    },
    {
        title: "docsmall PDF处理",
        desc: "PDF压缩、合并、拆分工具",
        href: "https://docsmall.com/?from=xiaomark.com",
    },
    {
        title: "Photopea",
        desc: "免费好用的在线PS工具",
        href: "https://www.photopea.com/?ref=91sotu.com",
    },
    {
        title: "Big Jpg",
        desc: "AI人工智能图片放大",
        href: "https://bigjpg.com/",
    },
];

export const clipTools = [
    {
        title: "Unsplash",
        desc: "高质量免费版权的图库素材",
        href: "https://unsplash.com/",
    },
    {
        title: "THE STOCKS",
        desc: "综合性的图片引擎 ",
        href: "http://thestocks.im/",
    },
    {
        title: "Splitshire",
        desc: "为设计师提供丰富的免费高分辨率图片",
        href: "https://www.splitshire.com/",
    },
    {
        title: "Iconfont",
        desc: "阿里旗下的矢量图标库",
        href: "https://www.iconfont.cn/",
    },
    {
        title: "Pixabay",
        desc: "免费高清图片资源网站",
        href: "https://pixabay.com/",
    },
    {
        title: "Pexels",
        desc: "提供海量共享图片素材的网站",
        href: "https://www.pexels.com/",
    },
];