<!--
 * @Author: gufs
 * @Date: 2021-03-11 10:12:50
 * @LastEditors: gufs
 * @LastEditTime: 2021-05-17 12:28:21
 * @Description: file content
-->
<template>
  <div class="solution-layout">
    <div class="banner">
      <div class="banner-title">
        <div class="title">
          解决方案
        </div>
        <div class="subtitle">
          针对零售行业，以多感官互动式的购物体验，提升门店流量和转化
        </div>
        <img
          class="banner-img"
          src="/dist/media/static-page/h5prezi/img/solution/solution-banner-bg.png"
        >
      </div>
    </div>
    <div class="solution-warp">
      <div
        v-for="(solution, i) in solutions"
        :id="solution.id"
        :key="i"
        class="solution-item"
      >
        <div class="info">
          <div
            class="info-item description"
            :style="{ float: (i % 2 === 0) ? 'right' : 'left'}"
          > 
            <div class="item-title">
              <span>{{ solution.title }}</span>
            </div>
            <div class="item-description">
              {{ solution.itemDesc }}
            </div>
          </div>
        </div>
        <img
          class="item-img"
          :src="'/dist/media/static-page/h5prezi/img/solution/' + solution.itemImg + '.png'"
        >
        <div class="item-swiper" :class="(i % 2 === 0) ? 'float-right' : 'float-left'">
          <div class="item-swiper-slide" 
                v-for="(slide, slideIndex) in solution.swiper" 
                :key="slideIndex">
            <img :src="slide">
          </div>
        </div>
      </div>
    </div>
    <div
      class="try-banner"
      :style="tryBannerStyle"
    >
      <div class="try-info">
        准备好尝试一下我们的产品了吗?
      </div>
      <a
        class="tryBtn"
        target="_blank"
        href="http://www.h5prezi.com/zh-hans/accounts/login/v2/"
      >在线体验</a>
    </div>
  </div>
</template>

<script>
import {solutions} from "../../store/solutions.js";

export default {
  data() {
    return {
      tryBannerStyle: '/dist/media/static-page/h5prezi/img/product/try-banner-bg.png',
      solutions
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.solution-layout {
  .banner {
    height: 380px;
    background: linear-gradient(to right, #0ABCFF, #0889FC);
    width: 100%;
    color: #fff;
    font-family: PingFang SC;
    font-weight: 400;
    .banner-title {
      max-width: 800px;
      margin: 0 auto;
      top: 100px;
      position: relative;
      text-align: left;
      .title {
        font-size: 44px;
        font-weight: 600;
      }
      .subtitle {
        font-size: 16px;
        line-height: 30px;
        margin-top: 16px;
        position: relative;
        width: 350px;
      }
      .banner-img {
        width: 361px;
        height: 269px;
        top: -200px;
        left: 450px;
        position: relative;
      }
    }
    
  }
  .solution-warp {
    .solution-item {
      .info {
        width: 1200px;
        margin: 0 auto;
        text-align: left;
        .info-item {
          margin-top: 85px;
        }
        .item-title {
          font-size: 30px;
          font-weight: 500;
          span {
            border-bottom: 2px; 
            border-bottom-style: solid; 
            border-bottom-color: #0773fc78; 
            padding-bottom: 10px;
          }
        }
        .item-description {
          width: 425px;
          font-size: 14px;
          color: #666666;
          line-height: 32px;
          margin-top: 30px;
        }
      }
      .item-img {
        width: 100%;
        margin-top: 60px;
        position: relative;
        z-index: 1;
      }
      .item-swiper {
        position: relative;
        margin-top: -32%;
        z-index: 99;
        width: 15%;
        right: 15%;
        .item-swiper-slide {
          width: 100%;
          margin-top: 20px;
          img {
            width: 100%;
          }
        }
      }
      .item-swiper.float-left {
        float: left;
        left: 19%;
      }
      .item-swiper.float-right {
        float: right;
        right: 19%;
      }
    }
  }
  .try-banner {
    height: 236px;
    width: 100%;
    text-align: center;

    .try-info {
      font-size: 36px;
      font-weight: 500;
      color: #222222;
      position: relative;
      top: 60px;
    }
    .tryBtn {
      background: #0773FC;
      border-radius: 22px;
      color: #fff;
      width: 161px;
      height: 44px;
      border-width: 0px;
      line-height: 44px;
      font-size: 18px;
      outline: none; 
      cursor: pointer;
      display: block;
      margin: 0 auto;
      position: relative;
      top: 80px;
    }
  }
}
</style>
