/*
 * @Author: gufs
 * @Date: 2020-07-28 11:00:26
 * @LastEditors: gufs
 * @LastEditTime: 2021-05-22 15:27:09
 * @Description: file content
 */
// export const HOST = 'http://www.h5prezi.com';
// export const HOST = 'http://devel.h5prezi.com';//location.origin;
let HOST = window.location.origin;
if (window.location.origin.indexOf('localhost') > -1 || window.location.origin.indexOf('192.168.1.8') > -1) {
    HOST = 'https://www.fastmock.site/mock/5f4ea2f66b9d015d1f047c0d91954e5d/api';
}

export const TEMPLATE_LIST = HOST + '/kendoui/project/for/website/';
export const TEMPLATE_TAG = HOST + '/tags/project/list/for/website/';