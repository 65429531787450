<!--
 * @Author: your name
 * @Date: 2021-03-16 18:42:57
 * @LastEditTime: 2021-05-13 18:40:22
 * @LastEditors: gufs
 * @Description: In User Settings Edit
 * @FilePath: /h5prezi(vue-cli)/src/views/HelpCenter.vue
-->
<template>
  <view>
    <ProductLayout
      :banner-img="bannerImg"
      :title="title"
      :subtitle="subtitle"
      :func-desc="funcDesc"
      :func-img="funcImg"
      :technology-img="technologyImg"
      :prod-swiper="prodSwiper"
    />
  </view>
</template>


<script>

import ProductLayout from '../../Layouts/ProductLayout';
export default {
    components: {
        ProductLayout
    },
    data() {
        return {
            title: '广告运营平台',
            bannerImg: '/dist/media/static-page/h5prezi/img/product/ad-banner-img.png',
            funcImg: 'ad-func',
            technologyImg: 'ad-technology',
            subtitle: '基于HTML5的运营服务发布平台 打破时空限制，颠覆传统的发布模式智能化的受众分析，精准化投放。',
            funcDesc: '集内容制作发布及广告运营平台功能于一体；以专业的运营系统，先进的发布技术，大数据统计分析能力，为LCD、LED屏幕在各行业的应用提供更完整更广泛的内容服务和广告增值服务。',
            prodSwiper: ['/dist/media/static-page/h5prezi/img/product/ad-slide1.png', '/dist/media/static-page/h5prezi/img/product/ad-slide2.png', '/dist/media/static-page/h5prezi/img/product/ad-slide3.png']
        }
    }
}
</script>