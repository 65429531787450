<!--
 * @Author: your name
 * @Date: 2021-03-16 18:42:57
 * @LastEditTime: 2021-05-13 18:40:41
 * @LastEditors: gufs
 * @Description: In User Settings Edit
 * @FilePath: /h5prezi(vue-cli)/src/views/HelpCenter.vue
-->
<template>
  <view>
    <ProductLayout
      :banner-img="bannerImg"
      :title="title"
      :subtitle="subtitle"
      :func-desc="funcDesc"
      :func-img="funcImg"
      :technology-img="technologyImg"
      :prod-swiper="prodSwiper"
    />
  </view>
</template>


<script>

import ProductLayout from '../../Layouts/ProductLayout';
export default {
    components: {
        ProductLayout
    },
    data() {
        return {
            title: '物联网平台',
            bannerImg: '/dist/media/static-page/h5prezi/img/product/internetofthinks-banner-img.png',
            funcImg: 'internetofthinks-func',
            subtitle: '借助从边缘扩展至云，并融合了全新 AI 和计算机视觉的物联网解决方案，充分利用您的数据。',
            funcDesc: '将物联网 (IoT) 付诸实践的基础设施、应用和安全解决方案<br/>可打造全新体验，利用从智能边缘捕获的数据促进运营智能化。',
            prodSwiper: ['/dist/media/static-page/h5prezi/img/product/internetofthinks-slide1.png', '/dist/media/static-page/h5prezi/img/product/internetofthinks-slide2.png', '/dist/media/static-page/h5prezi/img/product/internetofthinks-slide3.png']
        }
    }
}
</script>