<!--
 * @Author: your name
 * @Date: 2021-03-16 18:42:57
 * @LastEditTime: 2021-05-13 18:40:51
 * @LastEditors: gufs
 * @Description: In User Settings Edit
 * @FilePath: /h5prezi(vue-cli)/src/views/HelpCenter.vue
-->
<template>
  <view>
    <ProductLayout
      :banner-img="bannerImg"
      :title="title"
      :subtitle="subtitle"
      :func-desc="funcDesc"
      :func-img="funcImg"
      :technology-img="technologyImg"
      :prod-swiper="prodSwiper"
    />
  </view>
</template>


<script>

import ProductLayout from '../../Layouts/ProductLayout';
export default {
    components: {
        ProductLayout
    },
    data() {
        return {
            title: '视觉交互平台',
            bannerImg: '/dist/media/static-page/h5prezi/img/product/visual-banner-img.png',
            funcImg: 'visual-func',
            technologyImg: 'visual-technology',
            subtitle: '智能化视觉交互云平台, 致力于"AI+交互+大数据"在各行业的互动应用, 为行业提供最优质的服务,创造更大的价值和不断提高企业的内生力。',
            funcDesc: '以内容为核心的制作发布平台，完全可视化自由制作交互触控、动画、数据可视化、3D展示等多种内容',
            prodSwiper: ['/dist/media/static-page/h5prezi/img/product/visual-slide1.png', '/dist/media/static-page/h5prezi/img/product/visual-slide2.png', '/dist/media/static-page/h5prezi/img/product/visual-slide3.png']
        }
    }
}
</script>
