<!--
 * @Author: your name
 * @Date: 2021-03-09 19:03:08
 * @LastEditTime: 2021-05-10 17:23:39
 * @LastEditors: gufs
 * @Description: In User Settings Edit
 * @FilePath: /h5prezi(vue-cli)/src/components/ProductLayout.vue
-->
<template>
  <router-view />
</template>

<script>

export default {
  name: "BaseLayout",
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {
  }
};
</script>
<style scoped lang="less">

</style>
