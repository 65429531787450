/*
 * @Author: gufs
 * @Date: 2021-05-14 15:38:53
 * @LastEditors: gufs
 * @LastEditTime: 2021-05-17 12:10:45
 * @Description: file content
 */
export const solutions = [{
  title: '智慧餐厅',
  subtitle: '针对智慧餐厅，以多感官互动式的购物体验，提升门店流量和转化',
  itemDesc: '在餐饮行业下，餐客要的是高效等餐、舒心用餐；商家希望高效售卖、 提升坪效、增强客户黏性。我们的方案，从双方诉求出发，进行交叉分析， 提供场景化智慧餐厅升级解决方案。',
  itemImg: 'smart-restaurant',
  id: 'smart-restaurant',
  swiper: [
    '/dist/media/static-page/h5prezi/img/solution/smart-restaurant-slide1.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-restaurant-slide2.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-restaurant-slide3.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-restaurant-slide4.png'
  ]
}, {
  title: '新零售',
  subtitle: '针对新零售行业，以多感官互动式的购物体验，提升门店流量和转化',
  itemDesc: '新零售的升级通过智屏OS,实现全渠道数据链路的打通，不管线上线下，让顾客体会到同质、同价、同服务。在提升用户购物的同时，提升门店成交转换率。',
  itemImg: 'new-retail',
  id: 'new-retail',
  swiper: [
    '/dist/media/static-page/h5prezi/img/solution/new-retail-slide1.png', 
    '/dist/media/static-page/h5prezi/img/solution/new-retail-slide2.png', 
    '/dist/media/static-page/h5prezi/img/solution/new-retail-slide3.png', 
    '/dist/media/static-page/h5prezi/img/solution/new-retail-slide4.png'
  ]
}, {
  title: '广告运营',
  subtitle: '针对广告运营行业，以多感官互动式的购物体验，提升门店流量和转化',
  itemDesc: '作为专业广告运营平台，实现广告的智能排片，发布和统计。通过对接百度等线上广告接口，实现广告资源的线下发布，为线下多类屏幕提供广告运营服务。实现与第三方应用APP的互相调用和共享，线下屏幕实现“应用+广告”增值服务模式。',
  itemImg: 'ad-operation',
  id: 'ad-operation',
  swiper: [
    '/dist/media/static-page/h5prezi/img/solution/ad-operation-slide1.png', 
    '/dist/media/static-page/h5prezi/img/solution/ad-operation-slide2.png', 
    '/dist/media/static-page/h5prezi/img/solution/ad-operation-slide3.png', 
    '/dist/media/static-page/h5prezi/img/solution/ad-operation-slide4.png'
  ]
}, {
  title: '智慧社区',
  subtitle: '针对智慧社区，以多感官互动式的购物体验，提升门店流量和转化',
  itemDesc: '智慧社区是通过利用各种智能技术和方式，整合社区现有的各类服务资源为社区群众提供政务、商务、娱乐、教育、医护及生活互助等多种便捷服务的模式。从应用方向来看，“智慧社区”应实现“以智慧政务提高办事效率，以智慧民生改善人民生活，以智慧家庭打造智能生活。',
  itemImg: 'smart-community',
  id: 'smart-community',
  swiper: [
    '/dist/media/static-page/h5prezi/img/solution/smart-community-slide1.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-community-slide2.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-community-slide3.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-community-slide4.png'
  ]
}, {
  title: '智慧医疗',
  subtitle: '针对智慧医疗，以多感官互动式的购物体验，提升门店流量和转化',
  itemDesc: '在餐饮行业下，餐客要的是高效等餐、舒心用餐；商家希望高效售卖、提升坪效、增强客户黏性。我们的方案，从双方诉求出发，进行交叉分析， 提供场景化智慧餐厅升级解决方案。',
  itemImg: 'smart-medicine',
  id: 'smart-medicine',
  swiper: [
    '/dist/media/static-page/h5prezi/img/solution/smart-medicine-slide1.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-medicine-slide2.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-medicine-slide3.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-medicine-slide4.png'
  ]
}, {
  title: '智慧城市',
  subtitle: '针对智慧城市，以多感官互动式的购物体验，提升门店流量和转化',
  itemDesc: '智慧城市平台是依托" Onenet"云平台,充分利用物联网、大数据、移动互联网等信息技术,通过通信技术手段感測、分析、整合城市运行核心系统的各项关键信息,对民生、环保、公共安全、城市服务、工商业活动在内的各种需求做出智能响应,提供智慧城市综合应用解決方案',
  itemImg: 'smart-city',
  id: 'smart-city',
  swiper: [
    '/dist/media/static-page/h5prezi/img/solution/smart-city-slide1.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-city-slide2.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-city-slide3.png', 
    '/dist/media/static-page/h5prezi/img/solution/smart-city-slide4.png'
  ]
}];
