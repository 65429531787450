<!--
 * @Author: gufs
 * @Date: 2021-03-09 16:05:50
 * @LastEditors: gufs
 * @LastEditTime: 2021-11-11 17:56:30
 * @Description: file content
-->
<template>
  <main>
    <Header />
    <router-view v-slot="{ Component }">
      <transition>
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
    <iframe class="home" src="http://devel.h5prezi.com/zh-hans/blog/" v-show="$route.path == '/home'"/>
    <!-- <Footer /> -->
    <RigthHelper />
  </main>
</template>

<script>
import Header from './components/Header.vue'
// import Footer from './components/Footer.vue'
import RigthHelper from './components/RigthHelper.vue'

export default {
  name: 'App',
  components: {
    Header,
    // Footer,
    RigthHelper
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  min-width: 1200px;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}
iframe.home {
  width: 100%;
  height: 2695px;
  overflow: hidden;
  border-style: none;
}
</style>
