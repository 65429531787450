/*
 * @Author: gufs
 * @Date: 2021-05-12 19:52:22
 * @LastEditors: gufs
 * @LastEditTime: 2021-05-20 17:13:36
 * @Description: file content
 */
export const problemsMenu = [{
    title: "关于播放器中的保护机制",
    subMenu: [{
        title: "(1) 半夜重启",
        article: "autoReboot"
    }, {
        title: "(2) 捕获异常重启应用",
        article: "exceptionCapture"
    }
    // , {
    //     title: "(3) 捕获ANR重启应用",
    //     article: "anr"
    // }
]
}
// , {
//     title: "设备显示在线，但发布节目后一直显示下载失败",
//     subMenu: [{
//         title: "(1) 授权不匹配下载失败原因"
//     }, {
//         title: "(2) 版本不匹配失败解决方法"
//     }, {
//         title: "(3) 捕获ANR重启应用 "
//     }]
// }, {
//     title: "信发系统是否支持在操作系统centos 7.5上部署？",
//     subMenu: [{
//         title: "(1) 服务器私有化部署要求的操作"
//     }]
// }
];

export const problemsArticles = {
    autoReboot: `
    <article>
        <h1>半夜重启</h1>
        <p>
        #1 半夜重启动 <br>
        #2 捕获异常重启应用 <br>
        #3 捕获ANR重启应用 <br>
        #4 监测没在应用栈顶，重启应用 （对用户操作有干扰，暂时未启用）<br>
        #5 启用硬件狗 （需硬件厂家配合调试，未启用）<br>
        #6 延迟启动，防止资源未就绪<br>
        目前发布的版本支持上述机制，其中#5和硬件有关未实现；#4 干扰用户操作，暂时关闭。<br>
        系统在人为退出情况下是不自启动的，在系统异常时会自启动<br>
        </p>
    </article>
    `,
    exceptionCapture: `
    <article>
        <h1>统计分析</h1>
        <p>
        <h3>1、在发布历史的详情界面中，查看“下载状态”的不同状态（后缀）</h3>
        （1）版本不匹配失败 解决：说明播放器版本太旧，需要更新播放器，请联系服务商获取 <br>
        （2）时间冲突失败<br>
        （3）下载已过期 解决：请检查发布的节目的开始时间和结束时间<br>
        （4）未授权下载失败 解决：查看当前设备的授权是否到期（设备管理中设备视图中有小锁子标识），请联系服务商<br>
        （5）授权不匹配下载失败 原因：检查要发布的内容中是否有需要开放权限的功能： 如：叫	号功能、人脸识别功能等 解决：需要使用相关功能，请联系服务商获取<br>
        </p>
        <p>
        <h3>2、如果使用的是本地部署的服务器，且表现为：关闭防火墙后，节目下载成功 解决方法：开放2002， 80 端口（发布内容需要）</h3>
        </p>
    </article>
    `
};