<!--
 * @Author: your name
 * @Date: 2021-03-09 16:11:40
 * @LastEditTime: 2021-05-18 09:28:00
 * @LastEditors: gufs
 * @Description: In User Settings Edit
 * @FilePath: /h5prezi(vue-cli)/src/views/Home.vue
-->
<template>
  <!-- <iframe src="http://devel.h5prezi.com/zh-hans/blog/" /> -->
  <view></view>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {
  },
};
</script>

<style scoped>
  iframe {
    width: 100%;
    height: 2695px;
    overflow: hidden;
    border-style: none;
  }
</style>