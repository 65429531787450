<!--
 * @Author: your name
 * @Date: 2021-03-09 19:03:08
 * @LastEditTime: 2021-05-17 11:47:45
 * @LastEditors: gufs
 * @Description: In User Settings Edit
 * @FilePath: /h5prezi(vue-cli)/src/components/ProductLayout.vue
-->
<template>
  <div class="product-layout"> 
    <div class="banner">
      <div class="banner-title">
        <div class="prod-title">
          {{ title }}
        </div>
        <div class="prod-subtitle">
          {{ subtitle }}
        </div>
        <img
          class="banner-img"
          :src="bannerImg"
        >
      </div>
    </div>
    <div class="prod-info">
      <div class="prod-info-item prod-description"> 
        <div class="item-title">
          功能描述
        </div>
        <div class="item-description">
          <div class="func-description">
            {{ funcDesc }}
          </div>
        </div>
        <img
          class="prod-img prod-func-img"
          :src="'/dist/media/static-page/h5prezi/img/product/' + funcImg + '.png'"
        >
      </div>
      <div
        v-if="technologyImg"
        class="prod-info-item prod-technology"
      > 
        <div class="item-title">
          核心技术
        </div>
        <img
          class="prod-img prod-technology-img"
          :src="'/dist/media/static-page/h5prezi/img/product/' + technologyImg + '.png'"
        >
      </div>
      <div class="prod-info-item prod-scenarios"> 
        <div class="item-title">
          应用场景
        </div>
        <div
          v-if="prodSwiper.length"
          class="prod-swiper"
        >
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                v-for="(slide, i) in prodSwiper"
                :key="i"
                class="swiper-slide"
              >
                <img :src="slide">
              </div>
            </div>
          </div>
          <div
            class="swiper-button-prev"
            @click="swiperPrev"
          />
          <div
            class="swiper-button-next"
            @click="swiperNext"
          />
        </div>
      </div>
    </div>
    <div
      class="try-banner"
      :style="tryBannerStyle"
    >
      <div class="try-info">
        准备好尝试一下我们的产品了吗?
      </div>
      <a
        class="tryBtn"
        target="_blank"
        href="http://www.h5prezi.com/zh-hans/accounts/login/v2/"
      >在线体验</a>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
export default {
  name: "ProductLayout",
  props: {
    bannerImg: {
      type: String,
      default: ""
    },
    funcImg: {
      type: String,
      default: ""
    },
    technologyImg: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ''
    },
    funcDesc: {
      type: String,
      default: ''
    },
    prodSwiper: {
      type: Array,
      default() {return []}
    }
  },
  data() {
    return {
      tryBannerStyle: 'background-image: url(/dist/media/static-page/h5prezi/img/product/try-banner-bg.png)',
      swiper: null,
      timer: null
    }
  },
  beforeUnmount() {
    this.unmoutPage();
  },
  deactivated() {
    this.unmoutPage();
  },
  mounted() {
    this.swiper = new Swiper ('.swiper-container', {
      loop: true,
      slidesPerView: 3,
      autoplay: true,
      pagination: '.swiper-pagination',
      // 前进后退按钮 失效
      // nextButton: '.swiper-button-next',
      // prevButton: '.swiper-button-prev',
    });
    this.timer = setInterval(this.swiperNext, 3000);
  },
  methods: {
    unmoutPage() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (this.swiper) {
        this.swiper.destroy();
      }
    },
    swiperPrev() {
      this.swiper.slidePrev();
    },
    swiperNext() {
      this.swiper.slideNext();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.product-layout {
  .banner {
    height: 380px;
    background: #060030;
    width: 100%;
    color: #fff;
    font-family: PingFang SC;
    font-weight: 400;
    .banner-title {
      max-width: 800px;
      margin: 0 auto;
      top: 100px;
      position: relative;
      text-align: left;
      .prod-title {
        font-size: 44px;
        font-weight: 600;
      }
      .prod-subtitle {
        font-size: 16px;
        line-height: 30px;
        margin-top: 16px;
        position: relative;
        width: 464px;
      }
    }
    .banner-img {
      top: -200px;
      left: 550px;
      position: relative;
    }
  }
  .prod-info {
    width: 1200px;
    margin: 0 auto;
    .prod-info-item {
      margin-top: 85px;
      text-align: center;
      .func-description {
        width: 440px;
        margin:0 auto;
      }
    }
    .item-title {
      color: #222;
      font-size: 44px;
      font-weight: 600;
    }
    .item-description {
      text-align: center;
      font-size: 16px;
      color: #666666;
      line-height: 32px;
      margin-top: 30px;
    }
    .prod-func-img {
      width: auto;
      margin-top: 60px;
      max-width: 870px;
      max-height: 824px;
    }
    .prod-technology-img {
      max-width: 1200px;
      max-height: 1200px;
    }
    .prod-swiper {
      margin-top: 60px;
      margin-bottom: 120px;
      .swiper-container {
        width: 1200px;
        margin: 0 auto;
      }
      .swiper-button-prev {
        float: left;
        left: -15px;
      }
      .swiper-button-next {
        float: right;
        right: -15px;
      }
      .swiper-button-prev, .swiper-button-next {
        width: 44px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 44px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        position: relative;
        top: -130px;
      }
      .swiper-button-prev:after, .swiper-button-next:after {
        font-size: 22px;
        color: #000000;
      }
    }
  }
  .try-banner {
    height: 236px;
    width: 100%;
    text-align: center;

    .try-info {
      font-size: 36px;
      font-weight: 500;
      color: #222222;
      position: relative;
      top: 60px;
    }
    .tryBtn {
      background: #0773FC;
      border-radius: 22px;
      color: #fff;
      width: 161px;
      height: 44px;
      border-width: 0px;
      line-height: 44px;
      font-size: 18px;
      outline: none; 
      cursor: pointer;
      display: block;
      margin: 0 auto;
      position: relative;
      top: 80px;
    }
  }
}
</style>
