/*
 * @Author: gufs
 * @Date: 2021-03-09 16:05:50
 * @LastEditors: gufs
 * @LastEditTime: 2021-05-14 09:17:18
 * @Description: file content
 */

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less'
import "font-awesome/css/font-awesome.css"
import './plugins/element.js'
import 'remixicon/fonts/remixicon.css'
import installElementPlus from './plugins/element'

const app = createApp(App);
installElementPlus(app);
app.use(store)
    .use(router)
    // .use(axios)
    .use(less)
    .mount('#app');
