<!--
 * @Author: your name
 * @Date: 2021-03-16 18:42:57
 * @LastEditTime: 2021-05-13 18:40:32
 * @LastEditors: gufs
 * @Description: In User Settings Edit
 * @FilePath: /h5prezi(vue-cli)/src/views/HelpCenter.vue
-->
<template>
  <view>
    <ProductLayout
      :banner-img="bannerImg"
      :title="title"
      :subtitle="subtitle"
      :func-desc="funcDesc"
      :func-img="funcImg"
      :technology-img="technologyImg"
      :prod-swiper="prodSwiper"
    />
  </view>
</template>


<script>

import ProductLayout from '../../Layouts/ProductLayout';
export default {
    components: {
        ProductLayout
    },
    data() {
        return {
            title: '数据可视化平台',
            bannerImg: '/dist/media/static-page/h5prezi/img/product/datav-banner-img.png',
            funcImg: 'datav-func',
            technologyImg: 'datav-technology',
            subtitle: '提供丰富的可视化组件，包括常用的数据图表、图形、控件等。采用拖拽式自由布局，旨在帮助您快速定制和应用属于您自己的数据大屏。',
            funcDesc: '数据可视化平台能打破数据隔离，通过数据采集、清洗、分析到直观实时的数据可视化，即时呈现隐藏在瞬息万变且庞杂数据背后的业务洞察。同时也能通过交互式实时数据可视化大屏来实时监测企业数据，洞悉运营增长，助力智能高效决策。',
            prodSwiper: ['/dist/media/static-page/h5prezi/img/product/datav-slide1.png', '/dist/media/static-page/h5prezi/img/product/datav-slide2.png', '/dist/media/static-page/h5prezi/img/product/datav-slide3.png']
        }
    }
}
</script>