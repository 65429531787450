/*
 * @Author: gufs
 * @Date: 2021-05-12 09:38:05
 * @LastEditors: gufs
 * @LastEditTime: 2021-05-17 10:14:40
 * @Description: file content
 */
// import axios from 'axios';
async function interceptor(request) {
    if (request) {
        switch (request.status) {
            case 200:
                return await request.json();
            case 400:
                console.log('400错误的请求');
                return ;
            case 403:
                console.log('403会话已过期，是否跳转登录页面？');
                break;
            case 405:
                console.log('405当前操作不被允许');
                return ;
            case 429:
                console.log('429请求过多，先休息一下吧');
                return ;
            case 500:
                console.log('500服务器打瞌睡了');
                return ;
            default:
                console.log('服务器忙请稍后');
                return ;
        }
    }
    else {
        console.log('服务器忙请稍后');
        return ;
    }
    return ;
}

export async function post(url, data, opts) {
    let request = await fetch(url, Object.assign({}, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json', //默认格式
            'credentials': 'include', //包含cookie
            'mode': 'cors', //允许跨域
            'Accept-Language': 'zh-CN,zh;q=0.9'
        },
        body: JSON.stringify(data)
    }, opts || {}));
    
    return interceptor(request);
}

export async function get(url, opts) {
    let request = await fetch(url, Object.assign({}, {
        credentials: 'include',
        headers: {
            'Accept': 'application/json, text/plain, */*'
        }
    }, opts || {}));

    return await interceptor(request);
}
