<template>
  <header class="layout-header fixed-header top-menu">
    <div class="top-nav-header-main">
      <div class="top-nav-header-main-left">
        <div
          id="logo"
          class="top-nav-header-logo"
        >
          <img
            src="/dist/media/static-page/h5prezi/img/logo-word.jpg"
            alt="logo"
          >
        </div>
      </div>
      <div class="top-nav-header-menu">
        <ul class="menu top-nav-menu menu-root menu-horizontal">
          <li :class="['menu-item', [selectedMenu==='home' ? 'menu-item-selected' : '']]">
            <span class="menu-item-title">
              <router-link
                to="/home"
                @click="selectMenu('home')"
              >首页</router-link>
            </span>
          </li>
          <li :class="['menu-item', [selectedMenu==='template' ? 'menu-item-selected' : '']]">
            <span class="menu-item-title">
              <router-link
                to="/template-center"
                @click="selectMenu('template')"
              >模板中心</router-link>
            </span>
          </li>
          <li :class="['menu-item has-submenu', [selectedMenu==='product' ? 'menu-item-selected' : '']]">
            <div class="menu-item-title">
              产品服务
              <i
                class="fa fa-angle-down"
                aria-hidden="true"
              />
            </div>
            <div
              class="menu-submenu"
              name="product"
            >
              <div class="submenu-warp">
                <div class="submenu-warp-title">
                  产品服务
                </div>
                <div class="submenu-item-warp">
                  <span class="submenu-item">
                    <img
                      class="submenu-item-img"
                      src="/dist/media/static-page/h5prezi/icons/product-visual-interaction.png"
                    >
                    <span class="submenu-item-title">
                      <router-link
                        to="/products-services/visual-interaction-platform"
                        @click="selectMenu('product')"
                      >视觉交互平台</router-link>
                    </span>
                  </span>
                  <span class="submenu-item">
                    <img
                      class="submenu-item-img"
                      src="/dist/media/static-page/h5prezi/icons/product-ad-operation.png"
                    >
                    <span class="submenu-item-title">
                      <router-link
                        to="/products-services/advertising-operation-platform"
                        @click="selectMenu('product')"
                      >广告运营平台</router-link>
                    </span>
                  </span>
                  <span class="submenu-item">
                    <img
                      class="submenu-item-img"
                      src="/dist/media/static-page/h5prezi/icons/product-internet-of-things.png"
                    >
                    <span class="submenu-item-title">
                      <router-link
                        to="/products-services/internet-of-things-platform"
                        @click="selectMenu('product')"
                      >物联网平台</router-link>
                    </span>
                  </span>
                  <span class="submenu-item">
                    <img
                      class="submenu-item-img"
                      src="/dist/media/static-page/h5prezi/icons/product-datav.png"
                    >
                    <span class="submenu-item-title">
                      <router-link
                        to="/products-services/data-visualization-platform"
                        @click="selectMenu('product')"
                      >数据可视化平台</router-link>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </li>
          <li :class="['menu-item has-submenu', [selectedMenu==='solution' ? 'menu-item-selected' : '']]">
            <span class="menu-item-title">
              解决方案
              <i
                class="fa fa-angle-down"
                aria-hidden="true"
              />
            </span>
            <div
              class="menu-submenu"
              name="solution"
            >
              <div class="submenu-warp">
                <div class="submenu-warp-title">
                  解决方案
                </div>
                <div class="submenu-item-warp">
                  <span class="submenu-item">
                    <img
                      class="submenu-item-img"
                      src="/dist/media/static-page/h5prezi/icons/solution-smart-restaurant.png"
                    >
                    <span class="submenu-item-title">
                      <router-link
                        to="/solution#smart-restaurant"
                        @click="selectMenu('solution')"
                      >智慧餐厅</router-link>
                    </span>
                  </span>
                  <span class="submenu-item">
                    <img
                      class="submenu-item-img"
                      src="/dist/media/static-page/h5prezi/icons/solution-new-retail.png"
                    >
                    <span class="submenu-item-title">
                      <router-link
                        to="/solution#new-retail"
                        @click="selectMenu('solution')"
                      >新零售</router-link>
                    </span>
                  </span>
                  <span class="submenu-item">
                    <img
                      class="submenu-item-img"
                      src="/dist/media/static-page/h5prezi/icons/solution-ad-operation.png"
                    >
                    <span class="submenu-item-title">
                      <router-link
                        to="/solution#ad-operation"
                        @click="selectMenu('solution')"
                      >广告运营</router-link>
                    </span>
                  </span>
                  <span class="submenu-item">
                    <img
                      class="submenu-item-img"
                      src="/dist/media/static-page/h5prezi/icons/solution-smart-community.png"
                    >
                    <span class="submenu-item-title">
                      <router-link
                        to="/solution#smart-community"
                        @click="selectMenu('solution')"
                      >智慧社区</router-link>
                    </span>
                  </span>
                  <span class="submenu-item">
                    <img
                      class="submenu-item-img"
                      src="/dist/media/static-page/h5prezi/icons/solution-smart-medicine.png"
                    >
                    <span class="submenu-item-title">
                      <router-link
                        to="/solution#smart-medicine"
                        @click="selectMenu('solution')"
                      >智慧医疗</router-link>
                    </span>
                  </span>
                  <span class="submenu-item">
                    <img
                      class="submenu-item-img"
                      src="/dist/media/static-page/h5prezi/icons/solution-smart-city.png"
                    >
                    <span class="submenu-item-title">
                      <router-link
                        to="/solution#smart-city"
                        @click="selectMenu('solution')"
                      >智慧城市</router-link>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </li>
          <li :class="['menu-item', [selectedMenu==='help' ? 'menu-item-selected' : '']]">
            <span class="menu-item-title">
              <router-link
                to="/help-center"
                @click="selectMenu('help')"
              >帮助中心</router-link>
            </span>
          </li>
          <li :class="['menu-item', [selectedMenu==='success' ? 'menu-item-selected' : '']]">
            <span class="menu-item-title"> 
              <router-link
                to="/success-cases"
                @click="selectMenu('success')"
              >成功案例</router-link>
            </span>
          </li>
        </ul>
      </div>
      <div class="top-nav-header-main-right">
        <div
          id="link"
          class="top-nav-header-link"
        >
          <a
            href="/zh-hans/accounts/login/v2/"
            target="_blank"
            class="login-btn"
          >注册/登录</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    // console.log(this.$router.getRoutes());
    return {
      menuList: this.$router.getRoutes(),
      selectedMenu: 'home'
    }
  },
  mounted() {
    if (window.location.hash.indexOf('template') > -1) {
      this.selectedMenu = 'template';
    }
    else if (window.location.hash.indexOf('product') > -1) {
      this.selectedMenu = 'product';
    }
    else if (window.location.hash.indexOf('solution') > -1) {
      this.selectedMenu = 'solution';
    }
    else if (window.location.hash.indexOf('help-center') > -1) {
      this.selectedMenu = 'help';
    }
    else if (window.location.hash.indexOf('success-cases') > -1) {
      this.selectedMenu = 'success';
    }
    else {
      this.selectedMenu = 'home';
    }
  },
  methods: {
    selectMenu(menu) {
      this.selectedMenu = menu;
      if (menu === 'solution') {
        setTimeout(() => {
          let point = window.location.href.split('#')[window.location.href.split('#').length-1];
          console.log(point);
          document.getElementById(point).scrollIntoView(true);
        }, 0);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

header {
  height: 64px;
  width: 100%;
  line-height: 32px;
  background-color: #fff;
  box-shadow: -1px 4px 29px 1px rgba(51, 51, 51, 0.1);
}
header li {
  display: inline-block;
}
ul {
  margin-top: 16px;
}
a {
  text-decoration: none;
}
.menu-item a:visited {
  color: #000000;
}
.top-nav-header-main {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  height: 100%;
  padding: 0 16px;
}
.top-nav-header-main-left {
  display: flex;
  float: left;
}
.top-nav-header-main-right {
  float: right;
}
.top-nav-header-logo img {
  margin-top: 18px;
}
.top-nav-header-menu {
  flex: 1 1 0%;
}
.menu-item-selected .menu-item-title,
.menu-item-selected .menu-item-title a {
  color: #0773FC!important;
}
.menu-horizontal .menu-item {
  margin: 0 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  vertical-align: middle;
  height: 32px;
}
a.login-btn, a.login-btn:focus {
  width: 94px;
  height: 36px;
  background: #F0F6FF;
  border-radius: 5px;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #459AF5;
  border-width: 0px;
  outline: none;
  cursor: pointer;
  display: block;
  margin-top: 14px;
  text-decoration: none;
}

.menu-item-title {
  cursor: pointer;
}

.menu-submenu {
  visibility: hidden;
  padding-top: 34px;
  position: absolute;
  margin-left: -52px;
  z-index: 999;
}
.has-submenu:hover .menu-submenu,
.has-submenu .menu-submenu:hover,
.submenu-item-warp:hover {
  visibility: visible;
}

.submenu-warp {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 30px;
  padding-left: 30px;
  text-align: left;
  box-shadow: 0px 2px 12px 0px rgba(34, 34, 34, 0.1);
}
.submenu-warp:before,
.submenu-warp:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px;
  border-color: transparent;
  bottom: 100%;
  left: 55px;
  top: 15px;
}
.submenu-warp:before {
  border-bottom-color: #bbb;
}

.submenu-warp:after {
  border-bottom-color: #fff;
  margin-bottom: -1px;
}

.menu-submenu[name=product] .submenu-warp {
  width: 420px;
}
.menu-submenu[name=solution] .submenu-warp  {
  width: 620px;
}
.submenu-warp-title {
  font-size: 16px;
  color: #000000;
}
.submenu-warp-title:before {
  content: '';
  position: relative;
  left: -8px;
  height: 16px;
  width: 2px;
  top: 2px;
  display: inline-block;
}
.menu-submenu[name=product] .submenu-warp-title:before {
  background-color: #0773FC;
}
.menu-submenu[name=solution] .submenu-warp-title:before {
  background-color: #FFB80F;
}
.submenu-item-warp {
  margin-top: 20px;
  margin-left: -12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
}
.submenu-item {
  margin-bottom: 20px;
  width: 200px;;
}
.submenu-item-img {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
.submenu-item-title {
  font-size: 14px;
  vertical-align: middle;
  margin-left: 10px;
}
</style>
