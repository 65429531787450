<template>
  <div class="rigth-helper">
    <div class="shadow-box">
      <span class="helper-button">
        <div class="tip-box">
          <div class="contact-service">
            <div class="content-code shadow-box">
              <img
                class="content-qrcode"
                src="/dist/media/static-page/h5prezi/img/qrcode.jpg"
              >
              <div class="shadow-box-text">
                咨询电话
              </div>
              <div class="shadow-box-desc">
                010-84787306
              </div>
            </div>
          </div>
        </div>
        <div class="icon">
          <i class="ri-headphone-line" />         
        </div>
        咨询
      </span>
      <span class="helper-button">
        <div
          aria-hidden="true"
          class="tip-box"
        >
          <div class="contact-service">
            <div class="content-code shadow-box">
              <div
                class="contact-download"
                name="android"
              >
                <div class="download-desc">
                  <img src="/icons/android.png">
                  <span class="">Android下载</span>
                </div>
                <div class="download-btn">点击下载</div>
              </div>
              <div
                class="contact-download"
                name="windows"
              >
                <div class="download-desc">
                  <img src="/icons/windows.png">
                  <span class="">Windows下载</span>
                </div>
                <div class="download-btn">点击下载</div>
              </div>
            </div>
          </div>
        </div>
        <div class="icon">
          <i class="ri-download-2-line" />
        </div>
        下载
      </span>
    </div>
    <span
      class="mk-back-top shadow-box"
      @click="pageToHead"
    >
      <span class="action action-up">
        <i
          class="fa fa-angle-up"
          aria-hidden="true"
        />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "Rigth",
  data() {
    return {}
  },
  methods: {
    pageToHead() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rigth-helper {
  position: fixed;
  top: 20%;
  right: 24px;
  width: 50px;
  z-index: 1000;
  height: 170px;
  text-align: center;
  font-size: 12px;
}
.rigth-helper .tip-box {
  visibility: hidden;
  transition: display 5s;
}
.shadow-box {
  box-shadow: 0px 2px 12px 0px rgba(34, 34, 34, 0.2);
}

.rigth-helper .helper-button {
  width: 50px;
  height: 57px;
  background: #ffffff;
  cursor: pointer;
  display: block;
  padding-top: 5px;
}
.mk-back-top {
  margin-top: 7px;
  width: 50px;
  height: 50px;
  background: #ffffff;
  cursor: pointer;
  display: block;
}
.mk-back-top .action-up {
  top: 10px;
  position: relative;
}
.rigth-helper .helper-button .contact-service {
  position: fixed;
  right: 74px;
  padding-right: 20px;
}
.rigth-helper .helper-button .contact-service .content-code {
  width: 180px;
  height: 240px;
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(34, 34, 34, 0.1);
}
.contact-download img {
  width: 25px;
  height: 25px;
}
.contact-download {
  position: relative;
}
.contact-download[name=android] {
  top: 24px;
}
.contact-download[name=windows] {
  top: 52px;
}
.shadow-box-text {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
}
.shadow-box-desc {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
.content-qrcode {
  margin-top: 20px;
  width: 140px;
  height: 140px;
}
.rigth-helper .helper-button:hover,
.rigth-helper .mk-back-top:hover{
  background: #0773fc;
  color: #ffffff;
  transition: color 0.3s;
}
.rigth-helper .helper-button:hover .tip-box {
  visibility: visible;
}
.rigth-helper .tip-box:hover {
  visibility: visible;
}
.action-up {
  font-size: 20px;
}
.icon {
  font-size: 20px;
}
.download-btn {
  width: 140px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0px 2px 12px 0px rgba(34, 34, 34, 0.1);
  background-image: linear-gradient(to right, #62AAFC , #1484FC);
  color: #FFFFFF;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 40px;
  margin: 0 auto;
}
.download-desc {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 120px;
  margin-bottom: 16px;
}
</style>