/*
 * @Author: gufs
 * @Date: 2021-05-14 11:03:54
 * @LastEditors: gufs
 * @LastEditTime: 2021-05-14 11:12:56
 * @Description: file content
 */
export default function mediaToCloudPath(clip, prop) {
    let domain = "http://qn.media.h5prezi.com"
    let splitFilePath = function(path, split_str) {
        let fext = ['', ''];
        let parts = path.split(split_str);
        
        if (parts.length > 1) {
            let ext = parts[1].split('.');
            if (ext.length === 2) {
                ext[1] = '.' + ext[1];
                return ext;
            }
        }
        return fext;
    };
    let src = '';

    // get the prop node from clip info if it's an object.
    if (typeof(clip) === 'string')
        src = clip
    else if (typeof(clip) === 'object' && prop) {
        if (clip[prop])
            src = clip[prop];
    }

    // nothing to do for unknown types.
    if (!src) 
        return src;

    // the thumbnail for device snapshot.
    if (src.search('/media/mediafiles/snapshot_th_') == 0) {
        let fext = splitFilePath(src, '/media/mediafiles/snapshot_th_');
        return domain + '/media/mediafiles/snapshot_' + fext.join('') + '?imageView2/0/w/384';
    }
    // the device snapshot file.
    else if (src.search('/media/mediafiles/snapshot_') == 0) {
        return domain + src;
    }
    // framelayout cover image.
    else if (src.search('/media/mediafiles/framelayout_cover_') == 0) {
        return domain + src;
    }
    // the project cover image.
    else if (src.search('/media/mediafiles/project_cover_') == 0) {
        return domain + src;
    }
    // the thumbnail of video cover frame.
    else if (src.search('/media/mediafiles/vframe_th_') == 0) {
        let fext = splitFilePath(src, '/media/mediafiles/vframe_th_');
        return domain + '/media/mediafiles/' + fext.join('') + '?vframe/png/offset/1/w/384/h/' + (clip.width && Math.floor((192*(clip.height/clip.width))) || 108);
    }
    // video cover frame
    else if (src.search('/media/mediafiles/vframe_') == 0) {
        let fext = splitFilePath(src, '/media/mediafiles/vframe_');
        return domain + '/media/mediafiles/' + fext.join('') + '?vframe/png/offset/1/w/960/h/' + (clip.width && Math.floor((960*(clip.height/clip.width))) || 540);
    }
    // the slim picture of image clip.
    else if (src.search('/media/mediafiles/slim_') == 0) {
        let fext = splitFilePath(src, '/media/mediafiles/slim_');
        return domain + '/media/mediafiles/' + fext.join('') + '?imageView2/0/w/960';
    }
    // the thumbnail picture of image clip.
    else if (src.search('/media/mediafiles/thumbnail_') == 0) {
        let fext = splitFilePath(src, '/media/mediafiles/thumbnail_');
        return domain + '/media/mediafiles/' + fext.join('') + '?imageView2/0/w/720';
    }
    else if (src.search('/media/mediafiles/') == 0) {
        return domain + src;
    }
    else if (src.search('/media/') == 0) {
        return domain + src;
    }
    // nothing to do, return it directly if no cloud storage enabled.
    return src;
}