/*
 * @Author: gufs
 * @Date: 2021-05-12 19:52:22
 * @LastEditors: gufs
 * @LastEditTime: 2021-05-22 11:17:38
 * @Description: file content
 */
export const manualMenu = [{
    title: "操作说明",
    subMenu: [{
        title: "使用环境",
        article: "environment"
    }]
}, {
    title: "下载安装",
    subMenu: [{
        title: "App下载",
        article: "appDownload"
    }, {
        title: "安装App",
        article: "appInstall"
    }]
}, {
    title: "创建与登录",
    subMenu: [{
        title: "后台注册",
        article: "register"
    }, {
        title: "后台登录",
        article: "login"
    }]
}, {
    title: "节目管理",
    subMenu: [{
        title: "如何上传素材",
        article: "uploadClip"
    }, {
        title: "标签模块说明",
        article: "tagsMgr"
    }, {
        title: "新增标签",
        article: "addTag"
    }, {
        title: "编辑标签",
        article: "editTag"
    }, {
        title: "删除标签",
        article: "deleteTag"
    }, {
        title: "如何新建节目",
        article: "addProject"
    }, {
        title: "编辑器说明",
        article: "editor"
    }, {
        title: "如何制作节目",
        article: "makeProject"
    }, {
        title: "如何审核节目",
        article: "auditProject"
    }, {
        title: "如何发布节目",
        article: "deployProject"
    }, {
        title: "节目导出方法",
        article: "exportProject"
    }, {
        title: "节目导入方法",
        article: "importProject"
    }, {
        title: "模板使用方法",
        article: "templateMgr"
    }]
}, {
    title: "发布管理",
    subMenu: [{
        title: "节目发布详情",
        article: "deployProjectDetail"
    }]
}, {
    title: "设备管理",
    subMenu: [{
        title: "设备在线状态说明",
        article: "deviceOnline"
    }, {
        title: "如何添加设备",
        article: "addDevice"
    }, {
        title: "设备控制操作",
        article: "deviceOperation"
    }, {
        title: "设备状态监控",
        article: "deviceStatus"
    }]
}, {
    title: "GIS应用",
    subMenu: [{
        title: "GIS管理",
        article: "gis"
    }]
}, {
    title: "统计分析",
    subMenu: [{
        title: "统计分析",
        article: "statisticalAnalysis"
    }]
}, {
    title: "用户与权限",
    subMenu: [{
        title: "用户与权限说明",
        article: "userRoleMgr"
    }, {
        title: "角色管理",
        article: "addRole"
    }, {
        title: "添加组织机构",
        article: "addOrganization"
    }, {
        title: "添加用户",
        article: "addUser"
    }]
}, {
    title: "系统管理",
    subMenu: [{
        title: "系统设置",
        article: "sysSetting"
    }, {
        title: "日志管理",
        article: "logMgr"
    }]
}, {
    title: "联屏制作教程",
    subMenu: [{
        title: "联屏内容视频教程",
        article: "videoTutorial"
    }]
}];

export const manualArticles = {
    environment: `
    <article>
        <h1>使用环境</h1>
        <p>
            <h3>1、网络环境</h3>
            本系统服务需要服务器、客户端(计算机)连接网络后使用。
        </p>
        <p>
            <h3>2、客户端环境</h3>
            建议您使用最新版本 <a href="https://www.google.com/intl/zh-CN/chrome/" target="_blank">Chrome</a> 浏览器以获得最佳体验。
        </p>
        <p>
            <h3>3、官网地址</h3>
            <a href="http://www.h5prezi.com" target="_blank">http://www.h5prezi.com</a>
        </p>
    </article>
    `,
    appDownload: `
    <article>
        <h1>APP下载</h1>
        <p>
            <h3>1、用电脑打开H5prezi官网</h3>
            官网地址：<a href="http://www.h5prezi.com" target="_blank">http://www.h5prezi.com</a>
        </p>
        <p>
            <h3>2、选择平台下载安装app</h3>
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/appDownload.png">
        </p>
    </article>
    `,
    appInstall: `
    <article>
        <h1>安装APP</h1>
        <p>
            <h3>安装步骤</h3>
            (1) 把播放器拷贝到U盘中；<br>
            (2) 把U盘插入电视/盒子上；<br>
            (3) 在盒子中的文件管理中找到U盘，并在U盘中找到“h5prezi­635.apk”版本的播放器；<br>
            (4) 鼠标左键双击“h5prezi­635.apk”进行安装操作；<br>
            (5) 根据弹出的提示信息，一步一步安装；
        </p>
        <p>
            <h3>注意事项(必看) </h3>
            <p>
                1、安装过程中如果弹出的提示无法安装外部apk或安装失败解决方案：<br>
                方法一：先卸载设备上h5prezi播放器，然后使用鼠标左键安装。<br>
                方法二：在设备的“设置”中找到“外接来源”并勾选，然后使用鼠标左键安装(如果没有请忽略) 
            </p>
            <p>
                2、使用云端用户的“服务器地址”为：http://www.h5prezi.com “端口”为：2002<br>
                如果不对请点击设置更改为正确字段
                <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/appInstall1.png">
            </p>
        </p>
        <p>
            <h3>播放器安装成功后，后台设备还是显示不在线(问题排查) </h3>
            <p>
                1、确认网络连接和系统运行。(首次添加) <br>
                (1) 先检查一下设备的网络连接是否正常(可以通过浏览器打看网页来判断) <br>
                (2) 确认一下设备中播放器是否正在运行
            </p>
            <p>
                2、查看设备是否添加错误<br>
                (1) 要确认设备连接的是WiFi还是网线，设备连接WiFi和连接网线时的Mac地址是不同<br>
                的。如果是连接网线，一定要注意在播放器的“设置”中去除“WiFi”勾选项的勾选<br>
                (2) 设备中播放器的MAC地址一定要与系统后台中的设备的MAC地址相一致；
            </p>
            <p>
                3、当“Mac地址”是“02:00:00:00:00:00”时，需要重新查看设备的网络连接，确认以下信息：<br>
                (1) 用户现场使用的网络是网线还是WiFi？需要保证现场网络环境不会频繁变化；<br>
                (2) 如果确认使用的是网线，播放器配置菜单中的“使用WiFi网络获取MAC地址”是否去<br>
                掉勾选？(播放器默认用户使用的是WiFi) ；
            </p>
            <p>
                —双击鼠标右键获取播放器中MAC地址—<br>
                <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/appInstall2.png">
            </p>
            <p>
                —后台MAC地址—<br>
                <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/appInstall3.png">
            </p>
        </p>
    </article>
    `,
    register: `
    <article>
        <h1>后台注册</h1>
        <p>
            (1) 打开浏览器输入H5prezi后台登录网址，点击免费注册。<a href="http://www.h5prezi.com" target="_blank">http://www.h5prezi.com</a>
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/register1.png">
        </p>
        <p>
            (2) 填写相关信息，填好点击注册。
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/register2.png">
        </p>
    </article>
    `,
    login: `
    <article>
        <h1>后台登录</h1>
        <p>
            (1) 输入账号和密码，即可登录H5prezi后台进行操作。
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/login1.png">
        </p>
        <p>
            (2) H5prezi后台页面展示：
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/login2.png">
        </p>
    </article>
    `,
    uploadClip: `
    <article>
        <h1>如何素材上传</h1>
        <p>
            (1) 点击节目管理＞素材＞上传素材，按照提示进行资源上传<br>
            —注意事项—
            <table style="width: 531px; text-align: center;border-style: double;">
            <colgroup>
                <col width="267" span="1" />
                <col width="264" span="1" />
            </colgroup>
            <tbody>
                <tr style="background-color: #f5f5f5">
                    <td><strong>支持格式</strong></td>
                    <td><strong>限制</strong></td>
                </tr>
                <tr>
                    <td>jpeg</td>
                    <td>不大于10MB</td>
                </tr>
                <tr>
                    <td>png</td>
                    <td>不大于10MB</td>
                </tr>
                <tr>
                    <td>svg</td>
                    <td>不大于10MB</td>
                </tr>
                <tr>
                    <td>gif</td>
                    <td>不大于10MB</td>
                </tr>
                <tr>
                    <td>mp4</td>
                    <td>不大于512MB</td>
                </tr>
                <tr>
                    <td>mP3</td>
                    <td>不大于100MB</td>
                </tr>
                <tr>
                    <td>zip</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td>pdf</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td>apk</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td>csv</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td>xlsx</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td>xls</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td>bmp</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td>webm</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td>webp</td>
                    <td>—</td>
                </tr>
                <tr>
                    <td>psd</td>
                    <td>—</td>
                </tr>
            </tbody>
            </table>
        </p>
        <p>
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/uploadClip1.png">
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/uploadClip2.png">
        </p>
    </article>
    `,
    tagsMgr: `
    <article>
        <h1>标签模块说明</h1>
        <p>
            1、在标签栏中，标签名称后显示当前标签中内容数量；
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/tagsMgr1.png">
        </p>
        <p>
            2、固定标签：在标签栏中，有一些系统设置的固定标签，这些固定标签会在有符合该标签要求数据是显示出来(没有数据是不显示) ；
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/tagsMgr2.png">
        </p>
        <p>
        3、固定标签不可被编辑，创建与固定标签的同名标签后，系统会自动在标签名后生成日期数据；
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/tagsMgr3.png">
        </p>
    </article>
    `,
    addTag: `
    <article>
        <h1>新增标签</h1>
        <h3>GIF演示</h3>
        <p>
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addTag1.gif" style="width:100%">
        </p>
        <p>
            (1) 选中需要创建标签的素材文件＞点击标签
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addTag2.png">
        </p>
        <p>
            (2) 输入标签的名称＞点击确定
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addTag3.png">
        </p>
        <p>
            (3) 可以在左侧查看添加好的背景素材标签
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addTag4.png">
        </p>
    </article>
    `,
    editTag: `
    <article>
        <h1>编辑标签</h1>
        <p>
            (1) 向当前标签内添加数据
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/editTag1.gif" style="width:100%">
        </p>
        <p>
            (2) 删除当前标签内的部分数据
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/editTag2.gif" style="width:100%">
        </p>
    </article>
    `,
    deleteTag: `
    <article>
        <h1>删除标签</h1>
        <p>
        (1) 点击标签选择删除标签，删除之后刷新下标签栏
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deleteTag.gif" style="width:100%">
        </p>
    </article>
    `,
    addProject: `
    <article>
        <h1>如何新建节目</h1>
        <p>
            (1) 选择节目管理＞点击新建
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addProject1.png">
        </p>
        <p>
            (2) 选择新建节目的分辨率，默认为1920*1080，也可自定义尺寸＞点击新建
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addProject2.png">
        </p>
        <p>
            (3) 进入编辑器
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addProject3.png">
        </p>
    </article>
    `,
    editor: `
    <article>
        <h1>编辑器说明</h1>
        <p>
        (1) 初始页面
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/editor1.png">
        </p>
        <p>
        (2) 首页、场景页、层管理，包括添加，删除，复制等， 拖动可调整页面顺序
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/editor2.png">
        </p>
        <p>
        (3) 所有可添加的元素都在这里 选中拖入画板即可添加
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/editor3.png">
        </p>
        <p>
        (4) 针对当前页面的操作和辅助工具， 如前进，撤销，实时预览等
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/editor4.png">
        </p>
        <p>
        (5) 设置元素的样式、动画、点击事件， 如跳转页等
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/editor5.png">
        </p>
        <p>
        (6) 设置界面的名称，封面，和切换方式等 点击预览可实时预览节目效果
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/editor6.png">
        </p>
    </article>
    `,
    makeProject: `
    <article>
        <h1>如何制作节目</h1>
        <p>
            演示视频
            <video controls style="width:100%" src="/dist/media/static-page/h5prezi/img/helpCenter/articles/makeProject.mp4">
        </p>
    </article>
    `,
    auditProject: `
    <article>
        <h1>如何审核节目</h1>
        <p>
            GIF演示
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/auditProject1.gif" style="width:100%">
        </p>
        <p>
        (1) 选中节目点击提交审核，在弹出框中选择确定
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/auditProject2.png">
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/auditProject3.png">
        </p>
        <p>
        (2) 选中待审核节目，点击审核按钮，通过审核
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/auditProject4.png">
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/auditProject5.png">
        </p>
    </article>
    `,
    deployProject: `
    <article>
        <h1><如何发布节目</h1>
        <p>
        GIF演示
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deployProject1.gif" style="width:100%">
        </p>
        <p>
        (1) 选择需要发布的节目，点击发布按钮
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deployProject2.png">
        </p>
        <p>
        (2) 选择设备点击下一步(可以按住Shift或者勾选全选来多选设备，也可以用标签过滤或者搜索设备名称来查找设备) 
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deployProject3.png">
        </p>
        <p>
        (3) 选择默认布局，点击下一步
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deployProject4.png">
        </p>
        <p>
        (4) 选择播放的开始日期和结束日期，添加播放时间段，点击发布
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deployProject5.png">
        </p>
    </article>
    `,
    exportProject: `
    <article>
        <h1>节目导出方法</h1>
        <h4>导出说明：节目导出之后可以通过分享码的方式导入到不同的账号下面</h4>
        <p>
        (1) 选中需要导出的节目，点击节目导出
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/exportProject1.png">
        </p>
        <p>
        (2) 复制分享码
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/exportProject2.png">
        </p>
    </article>
    `,
    importProject: `
    <article>
        <h1>节目导入方法</h1>
        <p>
        (1) 登录需要导入的账号，点击节目导入
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/importProject1.png">
        </p>
        <p>
        (2) 粘贴分享码到输入框，点击新建导入
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/importProject2.png">
        </p>
    </article>
    `,
    templateMgr: `
    <article>
        <h1>模板使用方法</h1>
        <p>
        (1) 点击进入模板管理，选择行业分类，点击使用模板
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/templateMgr1.png">
        </p>
        <p>
        (2) 替换模板中的素材和文案，替换好之后点击设置修改节目名称，保存节目
            <video controls style="width:100%" src="/dist/media/static-page/h5prezi/img/helpCenter/articles/templateMgr2.mp4">
        </p>
        <p>
            (3) 进入节目管理，可以看到刚刚替换好的节目
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/templateMgr3.png">
        </p>
    </article>
    `,
    deployProjectDetail: `
    <article>
        <h1>节目发布详情</h1>
        <p>
            (1) 点击发布管理查看节目发布详情，点击发布进度可以查看节目是否下载完成，也可以点击更多＞详情查看
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deployProjectDetail1.png">
        </p>
        <p>
            (2) 进入详情可查看设备的在线情况，节目的下载状态等信息(在发布过程中，发布任务支持断点续传) 
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deployProjectDetail2.png">
        </p>
    </article>
    `,
    deviceOnline: `
    <article>
        <h1>设备在线状态说明</h1>
        <p>
        (1) 设备在线情况，绿色为在线，灰色为不在线，不在线请检查网络情况
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceOnline1.png">
        </p>
        <p>
        2.设备旁带小锁为未授权设备(或者授权到期设备) 。授权设备之后小锁自动消失
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceOnline2.png">
        </p>
    </article>
    `,
    addDevice: `
    <article>
        <h1>如何添加设备</h1>
        <p>
        设备正式授权码由经销商提供，或点击【试用30天】产生试用授权码，每个试用账户可添加5台试用设备。<br>
        GIF演示
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addDevice1.gif" style="width:100%">
        </p>
        <p>
            (1) 进入设备管理，点击添加设备
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addDevice2.png">
        </p>
        <p>
            (2) 填写设备信息
            ✅获取播放器中的MAC地址
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addDevice3.png">
            🔲输入设备名称，名称要清晰准确，方便查找对应设备
            ✅填写设备的MAC地址(MAC地址来源，安装在设备上的播放器中获取) 
            ✅填写授权码
            ✅点击确定，添加成功
        </p>
    </article>
    `,
    deviceOperation: `
    <article>
        <h1>设备控制操作</h1>
        <p>
        点击按钮来进行设备的控制操作
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceOperation1.png">
        </p>
        <p>
        (1) 截取快照
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceOperation2.gif" style="width:100%">
        </p>
        <p>
        (2) 编辑设备信息(可以修改设备的名称，MAC地址等信息) 
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceOperation3.png">
        </p>
        <p>
        (3) 删除设备(选中设备根据弹框提示进行删除) 注：设备请谨慎删除。
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceOperation4.png">
        </p>
        <p>
        (4) 设备详情(选中设备点击详情查看设备的详细信息) 
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceOperation5.gif" style="width:100%">
        </p>
        <p>
        (5) 设备控制(选中设备，点击控制按钮来操作设备的重启、关机、清除磁盘等操作) 
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceOperation6.png">
        </p>
        <p>
        (6) 设备设置(选中设备，点击设置按钮来操作设备的定时、开机动画、报警设置等操作) 
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceOperation7.png">
        </p>
        <p>
        (7) 通过给设备添加标签来区分设备的类型、机构、所在区域、等信息
            (标签功能操作跟素材标签相同) 
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceOperation8.png">
        </p>
    </article>
    `,
    deviceStatus: `
    <article>
        <h1>设备状态监控</h1>
        <p>
        (1) 对设备的IP地址、在线时长、设备类型、报警记录、外设等情况进行综合信息监控
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceStatus1.gif" style="width:100%">
        </p>
        <p>
        (2) 点击详情可查看对设备操作的命令历史和活动日志
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/deviceStatus2.gif" style="width:100%">
        </p>
    </article>
    `,
    gis: `
    <article>
        <h1>GIS管理</h1>
        为了更直观的对设置进行管理，可以利用地图可视化显示设备位置、分布信息
        <p>
        (1) 可以在设备控制中，点击设备按钮进行设备位置采集
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/gis1.png">
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/gis2.png">
        </p>
    </article>
    `,
    statisticalAnalysis: `
    <article>
        <h1>统计分析</h1>
        <p>
            <h2>节目统计</h2>
            对节目的发布次数、覆盖设备数、点击率进行统计
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/statisticalAnalysis1.png">
        </p>
        <p>
            <h2>设备统计</h2>
            对设备的点击率及发布次数进行统计
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/statisticalAnalysis2.png">
        </p>
    </article>
    `,
    userRoleMgr: `
    <article>
        <h1>用户与权限说明</h1>
        <p>
            <h4>用户管理包括:</h4>
            角色的管理添加、机构管理添加、用户的管理添加三部分；
        </p>
        <p>
            <h4>用户管理优势:</h4>
            方便用户根据自己的组织和运行的需要，进行用户操作授权和监控，大大地方便了系统管理，并且提高系统安全管控能力。
        </p>
        <p>
            <h4>用户管理创建流程：</h4>
            用户管理首先要添加角色，确定角色职责权限；然后再对角色进行分机构，方便统一集中化管理；最后添加用户，新建用户需要添加姓名、联系方式、选择权限级别、选择机构和角色职能。
        </p>
    </article>
    `,
    addRole: `
    <article>
        <h1>角色管理</h1>
        <p>
            角色说明：
            “角色”选项卡允许用户自行设计添加用户的角色，并可对角色进行授权，直接对所添加的角色进行权限控制。这些分配好的角色可以用于添加用户的时候，给用户选择角色，可以方便地进行用户权限控制；
        </p>
        <p>
        1、系统定义的基本角色，不建议修改
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addRole.png">
        </p>
    </article>
    `,
    addOrganization: `
    <article>
        <h1>添加组织机构</h1>
        <p>
            机构说明：
            (1) 系统支持三级机构设置，管理账号为一级账户，二级机构建立在一级下面，三级建立在二级下面。可在每个机构下建立多个用户；
            (2) 只有管理员才有权限进行机构设置；
            (3) 上级机构能看到下级机构的节目和设备；
        </p>
        <p>
            1、建立机构
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addOrganization.gif" style="width:100%">
        </p>
    </article>
    `,
    addUser: `
    <article>
        <h1>添加用户</h1>
        <p>
            添加说明：
            (1) 新建用户需要输入用户名，密码，组织机构等信息；
            (2) 用户需由管理员进行添加；
        </p>
        <p>
            1、填写用户信息
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addUser1.png">
        </p>
        <p>
            2、创建完成后点击确定
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addUser2.png">
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addUser3.png">
        </p>
        <p>
        3、添加完成后登录验证
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/addUser4.png">
        </p>
    </article>
    `,
    sysSetting: `
    <article>
        <h1>系统设置</h1>
        <p>
            设置说明：
            (1) 系统管理是对系统基本运行的设置，可以保证系统良好运行和进行运行分析。系统设置不建议修改。
            (2) 用户可对后台系统LOGO进行自定义设置
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/sysSetting1.png">
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/sysSetting2.png">
        </p>
    </article>
    `,
    logMgr: `
    <article>
        <h1>日志管理</h1>
        <p>
            日志管理说明：
            (1) 显示对用户登陆、系统变更、系统出错等重要信息。
            (2) 播放终端自动上传的日志。
            (3) 节目发布时间、接收时间等任务日志记录。
            (4) 支持日志信息导出功能。
            <img src="/dist/media/static-page/h5prezi/img/helpCenter/articles/logMgr.png">
        </p>
    </article>
    `,
    videoTutorial: `
    <article>
        <h1>联屏内容视频教程</h1>
        <p>
            <video controls style="width:100%" src="/dist/media/static-page/h5prezi/img/helpCenter/articles/videoTutorial.mp4"></video>
        </p>
        
    </article>
    `
};