<!--
 * @Author: your name
 * @Date: 2021-03-16 18:42:57
 * @LastEditTime: 2021-05-17 12:32:40
 * @LastEditors: gufs
 * @Description: In User Settings Edit
 * @FilePath: /h5prezi(vue-cli)/src/views/HelpCenter.vue
-->
<template>
  <div class="template-center">
    <div class="banner">
      <img
        class="banner-swiper-container"
        :src="bannerImgs[bannerImgIndex]"
      >
      <div class="banner-title">
        海量优质、精美模板推荐
      </div>
      <div class="search">
        <i class="fa fa-fw fa-search" />
        <input
          class="search-input"
          placeholder="请输入关键字搜索"
          v-bind="search"
        >
        <div
          class="search-btn"
          @click="searchHandler"
        >
          搜索
        </div>
      </div>
    </div>
    <div class="filter-btn-group">
      <div class="filter-btn-group-title">
        全部分类
      </div>
      <ul class="template-filter">
        <!-- <li class="template-tag-theme">
            <span class="template-tag-type">行业:</span>
            <span class="active template-tag-all" type="theme" name="all"> 全部 </span>
          </li> -->
        <li class="template-tag-tags tag-group-title">
          <span class="template-tag-type">行业:</span>
          <div class="template-tag-list">
            <span
              class="template-tag-all filter-btn-item"
              :class="tag==='all' ? 'active' : ''"
              type="tags__name"
              name="all"
              @click="selectTag"
            >全部</span>
            <span
              v-for="t in tags"
              :key="t.name"
              class="filter-btn-item"
              :class="tag===t.name ? 'active' : ''"
              type="tags__name"
              :tag-value="t.name"
              :name="t.name"
              @click="selectTag"
            >
              {{ t.name__display }}
            </span>
          </div>
        </li>
        <li class="template-tag-resolution tag-group-title">
          <span class="template-tag-type">屏幕:</span>
          <div class="template-tag-list">
            <span
              class="filter-btn-item"
              :class="portrait==='landscape' ? 'active' : ''"
              name="landscape"
              type="portrait"
              tag-value="0"
              @click="changePortrait"
            >横屏</span>
            <span
              class="filter-btn-item"
              :class="portrait==='portrait' ? 'active' : ''"
              name="portrait"
              type="portrait"
              tag-value="1"
              @click="changePortrait"
            >竖屏</span>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="templates.length"
      class="template-listview"
    >
      <div class="listview">
        <div
          v-for="template in templates"
          :key="template.uuid"
          class="listview-item"
          :class="portrait"
        >
          <div
            class="item-cover"
            :style="template.thumbnailBackground"
          >
            <a
              class="action preview"
              target="_blank"
              :href="template.previewLink"
            >
              <i class="ri-eye-fill" />
            </a>
          </div>
          <div class="listview-item-title">
            {{ template.name }}
          </div>
        </div>
      </div>
      <div class="listview-pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="count"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div
      v-else
      class="no-data"
    >
      <img src="/dist/media/static-page/h5prezi/img/templateCenter/no-data.png">
    </div>
  </div>
</template>


<script>
import { post } from "../utils/fetch.js";
import { TEMPLATE_LIST, TEMPLATE_TAG } from "../api/index.js";
import Base62 from "../utils/base62.js";
import mediaToCloudPath from "../utils/mediaToCloudPath.js";

export default {
  name: "Home",
  component: {},
  data() {
    return {
      bannerStyleTimer: '',
      bannerStyle: "background-image: url(/dist/media/static-page/h5prezi/img/templateCenter/banner-bg.png)",
      search: "",
      portrait: "landscape",
      tag: 'all',
      tags: [],
      templates: [],
      pageSize: 25,
      currentPage: 1, // 当前页码
      count: 1000, // 总数
      bannerImgs: [
        '/dist/media/static-page/h5prezi/img/templateCenter/banner-bg.png',
        '/dist/media/static-page/h5prezi/img/templateCenter/banner-bg1.png',
      ],
      bannerImgIndex: 0
    };
  },
  computed: {},
  beforeUnmount() {
    console.log('deactivated');
    this.unmoutPage();
  },
  deactivated() {
    console.log('deactivated');
    this.unmoutPage();
  },
  mounted() {
    let thiz = this;
    this.timer = setInterval(() => {
      if (thiz.bannerImgIndex < (thiz.bannerImgs.length-1)) {
        thiz.bannerImgIndex++;
      }
      else {
        thiz.bannerImgIndex = 0;
      }
    }, 10000);
  },
  created() {
    this.getTemplateTags();
    this.getTemplateListData();
  },
  methods: {
    unmoutPage() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    changePortrait(e) {
      this.portrait = e.srcElement.getAttribute('name');
      this.handleCurrentChange(1);
    },
    searchHandler() {
      this.handleCurrentChange(1);
    },
    selectTag(e) {
      this.tag = e.srcElement.getAttribute('name');
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTemplateListData();
    },
    getTemplateListData: async function() {
      let templateDataColumns = [
        {
          field: "uuid",
          in_model: true,
        },
        {
          field: "name",
          in_model: true,
        },
        {
          field: "pk",
          in_model: true,
        },
        {
          field: "thumbnail",
          in_model: true,
        },
      ];

      let filter = {
        logic: "and",
        filters: [{
          field: 'portrait', operator: 'eq', value: (this.portrait==='landscape' ? 0 : 1 )
        }]
      };
      if (this.tag !== 'all') {
        filter.filters.push({
          field: 'tags__name', operator: 'eq', value: this.tag
        })
      }
      if (this.search.length) {
        filter.filters.push({
          field: 'name', operator: 'contains', value: this.search
        })
      }

      let result = await post(TEMPLATE_LIST, {
        filter: filter,
        sort: {
          0: { field: "created", dir: "desc" },
        },
        columns: templateDataColumns,
        skip: (this.currentPage - 1) * this.pageSize,
        take: this.pageSize,
      });

      this.count = result.count;
      this.templates = this.formatTemplatesData(result.payload);
    },
    getTemplateTags: async function() {
      let result = await post(TEMPLATE_TAG, {
        types: ["reserved", "tags"]
      });
      this.tags = result.results.tags;
    },
    getTemplateData() {},
    formatTemplatesData(templatesData) {
      return templatesData.map(d => {
        return {
          uuid: d.uuid,
          name: d.name,
          pk: d.pk,
          thumbnail: d.thumbnail,
          thumbnailBackground: "background-image:url(" + mediaToCloudPath(d.thumbnail) + ")",
          previewLink: '/play/' + Base62.encode(d.pk)
        }
      });
    }
  },
};
</script>

<style scoped lang="less">
.template-center {
  max-width: 1200px;
  margin: 0 auto;
  .banner {
    text-align: center;
    height: 260px;
    margin-top: 20px;
    .banner-swiper-container {
      width: 1200px;
      height: 260px;
      position: relative;
      z-index: 1;
    }
    .banner-title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      position: relative;
      top: -180px;
      z-index: 99;
    }
    .search {
      margin: 0 auto;
      width: 540px;
      height: 42px;
      background: #ecedf0;
      border-radius: 5px;
      position: relative;
      top: -160px;
      display: inline-block;
      z-index: 99;
      i.fa-search {
        margin-left: 17px;
        color: #999999;
      }
      .search-btn {
        width: 80px;
        height: 42px;
        float: right;
        border-radius: 0px 5px 5px 0px;
        background: #e3e4e9;
        line-height: 42px;
        color: #666666;
        cursor: pointer;
        display: inline-block;
      }
      .search-input {
        height: 100%;
        width: 418px;
        background: none;
        outline: none;
        border: none;
        display: inline-block;
      }
      .search-icon {
        width: 46px;
        height: 100%;
        display: inline;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .filter-btn-group {
    text-align: left;
    margin-top: 40px;
    .filter-btn-group-title {
      font-size: 16px;
      margin-left: 10px;
    }
    .filter-btn-group-title:before {
      content: "";
      position: relative;
      left: -8px;
      height: 16px;
      width: 2px;
      top: 2px;
      display: inline-block;
      background-color: #0773fc;
    }
    .template-filter {
      padding: 0;
      .tag-group-title {
        list-style: none;
        margin-top: 16px;
        margin-left: -10px;
        span {
          margin-right: 15px;
          color: rgba(63, 68, 82, 1);
          background: #fff;
          padding: 7px 12px;
          cursor: pointer;
          display: inline-block;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 13px;
        }
        .template-tag-list {
          margin-top: -27px;
          left: 65px;
          width: calc(100% - 50px);
          position: relative;
          .filter-btn-item {
            border-radius: 5px;
            margin-bottom: 20px;
          }
          .filter-btn-item.active {
            background: #e9f3ff;
            color: #0773fc;
          }
        }
      }
    }
  }
  .template-listview {
    border-top-style: solid;
    border-top-width: 2px;
    border-top-color: #dedede;
    .listview {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      width: 1220px;
      .listview-item {
        margin-right: 18px;
        margin-top: 20px;
        .item-cover {
          border-radius: 5px;
          background: #dddde4 none no-repeat scroll 50%/contain;
          background-size: cover;
          border-style: solid;
          border-width: 0.5px;
          border-color: #dddde4;
          
          .action {
            position: absolute;
            width: 32px;
            height: 32px;
            background: #ffffffe6;
            border-radius: 5px;
            visibility: hidden;
            cursor: pointer;
            text-align: center;
            line-height: 32px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          }
          .action.preview {
            margin-top: 10px;
            margin-left: 10px;
          }
        }
        .item-cover:hover {
          .action {
            visibility: visible;
          }
        }
        .listview-item-title {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-top: 16px;
        }
      }
      .listview-item.landscape {
        .item-cover {
          width: 224px;
          height: 126px;
        }
      }
      .listview-item.portrait {
        .item-cover {
          width: 224px;
          height: 398px;
        }
      }
    }
    .listview-pagination {
      width: 1200px;
      margin-top: 60px;
      margin-bottom: 90px;
      text-align: center;
    }
  }
  .no-data {
    border-top-style: solid;
    border-top-width: 2px;
    border-top-color: #dedede;
    text-align: center;
    height: 900px;
    img {
      margin-top: 266px;
    }
  }
}
</style>