import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import TemplateCenter from '../views/TemplateCenter.vue';
import HelpCenter from '../views/HelpCenter.vue';
import SuccessCases from '../views/SuccessCases.vue';

import BaseLayout from '../Layouts/BaseLayout.vue';

import VisualInteractionPlatform from '../views/ProductsServices/VisualInteractionPlatform.vue';
import AdvertisingOperationPlatform from '../views/ProductsServices/AdvertisingOperationPlatform.vue';
import InternetOfThingsPlatform from '../views/ProductsServices/InternetOfThingsPlatform.vue';
import DataVisualizationPlatform from '../views/ProductsServices/DataVisualizationPlatform.vue';
import Solution from '../views/Solution/Solution.vue';
// import SmartRestaurant from '../views/Solution/SmartRestaurant.vue';
// import NewRetail from '../views/Solution/NewRetail.vue';
// import AdOperation from '../views/Solution/AdOperation.vue';
// import SmartCommunity from '../views/Solution/SmartCommunity.vue';
// import SmartMedicine from '../views/Solution/SmartMedicine.vue';
// import SmartCity from '../views/Solution/SmartCity.vue';

export const routes = [
  {
    path: '/',
    redirect: '/home'
  }, 
  {
    path: '/home',
    name: '首页',
    icon: '',
    component: Home,
  },
  {
    path: '/template-center',
    name: '模板中心',
    icon: '',
    component: TemplateCenter,
  },
  {
    path: '/products-services',
    name: '产品服务',
    icon: '',
    component: BaseLayout,
    children: [{
      path: '/products-services/visual-interaction-platform',
      name: '视觉交互平台',
      icon: 'smile',
      component: VisualInteractionPlatform
    }, {
      path: '/products-services/advertising-operation-platform',
      name: '广告运营平台',
      icon: 'smile',
      component: AdvertisingOperationPlatform
    }, {
      path: '/products-services/internet-of-things-platform',
      name: '物联网平台',
      icon: 'smile',
      component: InternetOfThingsPlatform
    }, {
      path: '/products-services/data-visualization-platform',
      name: '数据可视化平台',
      icon: 'smile',
      component: DataVisualizationPlatform
    }],
  },
  // {
  //   path: '/solution',
  //   name: '解决方案',
  //   icon: '',
  //   component: BaseLayout,
  //   children: [{
  //     path: '/solution/smart-restaurant',
  //     name: '智慧餐厅',
  //     icon: 'smile',
  //     component: SmartRestaurant
  //   }, {
  //     path: '/solution/new-retail',
  //     name: '新零售',
  //     icon: 'smile',
  //     component: NewRetail
  //   }, {
  //     path: '/solution/ad-operation',
  //     name: '广告运营',
  //     icon: 'smile',
  //     component: AdOperation
  //   }, {
  //     path: '/solution/smart-community',
  //     name: '智慧社区',
  //     icon: 'smile',
  //     component: SmartCommunity
  //   }, {
  //     path: '/solution/smart-medicine',
  //     name: '智慧医疗',
  //     icon: 'smile',
  //     component: SmartMedicine
  //   }, {
  //     path: '/solution/smart-city',
  //     name: '智慧城市',
  //     icon: 'smile',
  //     component: SmartCity
  //   }],
  // },
  {
    path: '/solution',
    name: '解决方案',
    icon: '',
    component: Solution
  },
  {
    path: '/help-center',
    name: '帮助中心',
    icon: '',
    component: HelpCenter
  },
  {
    path: '/success-cases',
    name: '成功案例',
    icon: '',
    component: SuccessCases
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
